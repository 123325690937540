












































































































































































































import { PropType } from 'vue'
import mixins from 'vue-typed-mixins'
import { getElasticSearchClient } from '@/services/elasticSearch'
import EditCustomerNotes from '@/components/customers/EditCustomerNotes.vue'
import OrdersTableMixin from '@/mixins/OrdersTable.mixin'
import QueryParamsMixin from '@/mixins/QueryParams.mixin'
import Separator from '@/components/shared/Separator.vue'
import pluralize from 'pluralize'
import { differenceInDays } from 'date-fns'
import { Customer, Price } from '../../../../shared/types/types'

interface Row {
  orderId: string
  createdAt: Date
  customerName: string
  totalAmount: Price
  status: string
  paymentStatus: string
  fulfillmentStatus: string
  items: number
  deliveryMethod: string
}

export default mixins(OrdersTableMixin, QueryParamsMixin).extend({
  name: 'CustomerPrimary',
  components: {
    Separator,
  },
  props: {
    customer: {
      type: Object as PropType<Customer>,
      required: true,
    },
    createdAt: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchResult: [] as Record<string, any>,
    }
  },
  computed: {
    query(): Record<string, any> {
      return {
        query: {
          term: {
            userId: this.customer.customerId,
          },
        },
        track_total_hits: true,
        sort: {
          createdAt: 'desc',
        },
        size: 1000,
      }
    },
    nbHits(): number {
      return this.searchResult ? this.searchResult.hits.total.value : 0
    },
    rows(): Row[] {
      if (!this.searchResult || this.searchResult.length === 0) {
        return []
      }
      return this.searchResult.hits.hits.map((hit: Record<string, any>) => {
        const order = hit._source
        const row: Row = {
          orderId: order.orderId,
          createdAt: new Date(order.createdAt),
          customerName: [
            order.customer.firstName,
            order.customer.lastName,
          ].join(' '),
          totalAmount: order.totalPrice,
          status: order.status,
          paymentStatus: order.paymentStatus,
          fulfillmentStatus: order.fulfillmentStatus,
          items: order.lineItems.reduce(
            (acc: number, { quantity }: { quantity: number }) => acc + quantity,
            0
          ),
          deliveryMethod: order.shippingMethod
            ? order.shippingMethod.zone.name
            : 'N/A',
          ...(order.dispute
            ? {
                disputeStatus: order.dispute.status,
              }
            : {}),
        }
        return row
      })
    },
    customerName(): string {
      return `${this.customer.firstName} ${this.customer.lastName}`
    },
    filterString(): string {
      return `customer.customerId:"${this.customer.customerId}"`
    },
    orderCountText(): string {
      return pluralize(
        'order',
        this.customer?.analytics?.ordersCount || 0,
        true
      )
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData(): Promise<void> {
      const elasticSearch = getElasticSearchClient()
      const { data } = await elasticSearch.post('/orders/_search', this.query)
      this.searchResult = data
    },
    editCustomerNotes(editCustomer: any) {
      this.$buefy.modal.open({
        parent: this,
        component: EditCustomerNotes,
        hasModalCard: true,
        events: {
          'update-customer-note': (info: any) => {
            this.$emit('update-customer', info)
          },
        },
        props: { editCustomer },
        trapFocus: true,
      })
    },
    dateDiff(createdAt: string): string {
      return pluralize(
        'day',
        differenceInDays(new Date(), new Date(createdAt)),
        true
      )
    },
    rowItems(item: number): string {
      return pluralize('item', item, true)
    },
  },
})
