


























































import Vue from 'vue'
import EditCustomerContactInfo from '@/components/customers/EditCustomerContactInfo.vue'
import AddAddress from '@/components/customers/AddAddress.vue'
import AddressCard from '../shared/AddressCard.vue'
import Separator from '@/components/shared/Separator.vue'
export default Vue.extend({
  name: 'CustomerInfo',
  components: {
    Separator,
    AddressCard,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
    enableCopy: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      customerInfo: { ...this.customer },
    }
  },
  computed: {
    mailto(): string {
      return `mailto:${this.customerInfo.email}`
    },
  },
  methods: {
    addAddress(addressName: string) {
      this.$buefy.modal.open({
        parent: this,
        component: AddAddress,
        hasModalCard: true,
        props: { addressName },
        trapFocus: true,
      })
    },
    editCustomerContactInfo(customerInfo: any) {
      this.$buefy.modal.open({
        parent: this,
        component: EditCustomerContactInfo,
        hasModalCard: true,
        events: {
          'update-customer-info': (info: any) => {
            this.customerInfo = { ...info }
            this.$emit('update-customer', info)
          },
        },
        props: { customerInfo },
        trapFocus: true,
      })
    },
  },
})
