import Vue from 'vue'
import {
  DisputeStatusEnum,
  FulfillmentStatusEnum,
  Order,
  OrderStatusEnum,
  PaymentStatusEnum,
  Price,
  RiskLevelEnum,
} from '../../../shared/types/types'
import { formatDate, formatPrice } from '@/lib/utils'

enum PaymentStatus {
  Paid = 'paid',
  PartiallyRefunded = 'partiallyRefunded',
  Refunded = 'refunded',
}

export default Vue.extend({
  name: 'OrdersMixin',
  data() {
    return {
      isLoading: false,
      sort: [
        {
          createdAt: { order: 'desc' },
        },
      ],
    }
  },
  computed: {
    statusFilterOptions(): Record<string, string>[] {
      return [
        {
          label: 'Open',
          value: OrderStatusEnum.Open,
        },
        {
          label: 'Canceled',
          value: OrderStatusEnum.Canceled,
        },
      ]
    },
    paymentFilterOptions(): Record<string, string>[] {
      return [
        {
          label: 'Paid',
          value: PaymentStatusEnum.Paid,
        },
        {
          label: 'Partially Refunded',
          value: PaymentStatusEnum.PartiallyRefunded,
        },
        {
          label: 'Refunded',
          value: PaymentStatusEnum.Refunded,
        },
        {
          label: 'Unpaid',
          value: PaymentStatusEnum.Unpaid,
        },
        {
          label: 'Requires Action',
          value: PaymentStatusEnum.RequiresAction,
        },
        {
          label: 'Processing',
          value: PaymentStatusEnum.Processing,
        },
      ]
    },
    disputeStatusFilterOptions(): Record<string, string>[] {
      return [
        {
          label: 'Needs Response',
          value: DisputeStatusEnum.NeedsResponse,
        },
        {
          label: 'Warning Needs Response',
          value: DisputeStatusEnum.WarningNeedsResponse,
        },
        {
          label: 'Warning Under Review',
          value: DisputeStatusEnum.WarningUnderReview,
        },
        {
          label: 'Warning Closed',
          value: DisputeStatusEnum.WarningClosed,
        },
        {
          label: 'Under Review',
          value: DisputeStatusEnum.UnderReview,
        },
        {
          label: 'Charge Refunded',
          value: DisputeStatusEnum.ChargeRefunded,
        },
        {
          label: 'Won',
          value: DisputeStatusEnum.Won,
        },
        {
          label: 'Lost',
          value: DisputeStatusEnum.Lost,
        },
      ]
    },
    fulfillmentFilterOptions(): Record<string, string>[] {
      return [
        {
          label: 'Fulfilled',
          value: FulfillmentStatusEnum.Fulfilled,
        },
        {
          label: 'Partially Fulfilled',
          value: FulfillmentStatusEnum.Partial,
        },
        {
          label: 'Unfulfilled',
          value: FulfillmentStatusEnum.Unfulfilled,
        },
      ]
    },
    riskFilterOptions(): Record<string, string>[] {
      return [
        {
          label: 'Highest',
          value: RiskLevelEnum.Highest,
        },
        {
          label: 'Elevated',
          value: RiskLevelEnum.Elevated,
        },
        {
          label: 'Normal',
          value: RiskLevelEnum.Normal,
        },
        {
          label: 'Not Assessed',
          value: RiskLevelEnum.NotAssessed,
        },
        {
          label: 'Unknown',
          value: RiskLevelEnum.Unknown,
        },
      ]
    },
  },
  methods: {
    handleSort(field: string, order: 'asc' | 'desc') {
      const sort: Record<string, any>[] = []
      switch (field) {
        case 'date': {
          sort.push({
            createdAt: { order },
          })
          break
        }
        case 'customer': {
          sort.push({
            ['customer.firstName.keyword']: { order },
          })
          sort.push({
            ['customer.lastName.keyword']: { order },
          })
          break
        }
        case 'gross': {
          sort.push({
            ['totalPrice.amount']: { order },
          })
          break
        }
        case 'discount': {
          sort.push({
            ['totalDiscountPrice.amount']: { order },
          })
          break
        }
        case 'refunds': {
          sort.push({
            ['totalRefund.amount']: { order },
          })
          break
        }
        default: {
          sort.push({
            [field]: { order },
          })
        }
      }

      if (JSON.stringify(sort) === JSON.stringify(this.sort)) {
        return
      }

      // @ts-ignore
      this.sort = sort
    },
    viewOrder(order: Order) {
      this.$router.push(`/orders/${order.orderId}`)
    },
    setLoading(value: boolean) {
      this.isLoading = value
    },
    getStatusLabelFromValue(
      value: string,
      filterOptions: Record<string, string>[]
    ) {
      return (filterOptions.find((option) => option.value === value) || {})
        .label
    },
    getTagTypeForFulfillmentStatus(value: string) {
      if (value === FulfillmentStatusEnum.Unfulfilled) {
        return 'is-warning'
      }

      if (value === FulfillmentStatusEnum.Partial) {
        return 'is-warning is-light'
      }

      if (value === FulfillmentStatusEnum.Fulfilled) {
        return 'is-light'
      }
    },
    getTagTypeForPaymentStatus(value: string) {
      if (value === PaymentStatus.PartiallyRefunded) {
        return 'is-success is-light'
      }

      if (value === PaymentStatus.PartiallyRefunded) {
        return 'is-success'
      }
    },
    cellClasses(order: Record<string, any>) {
      if (order.status === OrderStatusEnum.Canceled) {
        return {
          'line-through': true,
        }
      }
    },
    shouldShowRiskIcon(order: Record<string, any>) {
      return (
        order.riskLevel === RiskLevelEnum.Elevated ||
        order.riskLevel === RiskLevelEnum.Highest
      )
    },
    getAlertTypeForRiskLevel(risk: string) {
      if (risk === RiskLevelEnum.Elevated) {
        return 'is-warning'
      }

      if (risk === RiskLevelEnum.Highest) {
        return 'is-danger'
      }
    },
    formattedPrice(totalAmount: Price) {
      return formatPrice(totalAmount)
    },
    formatDate,
  },
})
