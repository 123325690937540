






















import Vue from 'vue'
import CustomerPrimary from '@/components/customers/CustomerPrimary.vue'
import CustomerInfo from '@/components/customers/CustomerInfo.vue'
import NavigateBack from '@/components/shared/NavigateBack.vue'
import { getCustomerById } from '@/lib/user'
import { Customer, Maybe } from '../../../../shared/types/types'
import Loader from '../../components/shared/Loader.vue'

export default Vue.extend({
  name: 'CustomerDetails',
  components: {
    NavigateBack,
    CustomerPrimary,
    CustomerInfo,
    Loader,
  },
  data() {
    return {
      customer: {} as Maybe<Customer>,
      isLoading: true,
      createdAt: '',
    }
  },
  async created() {
    if (this.$route.params.id) {
      this.isLoading = true
      try {
        this.customer = await getCustomerById(this.$route.params.id)
        this.createdAt = this.customer?.createdAt || ''
        this.isLoading = false
      } catch (e) {
        this.$buefy.toast.open({
          message: 'Something went Wrong. Please try again later.',
          position: 'is-bottom',
          type: 'is-danger',
        })
      }
    }
  },
  methods: {
    customerUpdate(info: Customer) {
      this.customer = info
    },
  },
})
