






























import Vue, { PropType } from 'vue'
import { updateCustomerInfo } from '../../lib/user'
import { CustomerInput, Customer } from '../../../../shared/types/types'

export default Vue.extend({
  name: 'EditCustomerNotes',
  props: {
    editCustomer: {
      type: Object as PropType<Customer>,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      customerInfo: {} as CustomerInput,
    }
  },
  created() {
    this.customerInfo = {
      firstName: this.editCustomer?.firstName || '',
      email: this.editCustomer?.email || '',
      customerId: this.editCustomer?.customerId || '',
      note: this.editCustomer?.note || '',
    }
  },
  methods: {
    async saveCustomerInfo() {
      this.isLoading = true
      try {
        const response = await updateCustomerInfo(
          this.customerInfo,
          this.customerInfo.customerId
        )
        if (response) {
          this.$buefy.toast.open({
            message: 'Customer Note Updated Successfully!',
            type: 'is-success',
          })
          this.$emit('update-customer-note', response)
          this.$emit('close')
          this.isLoading = false
        }
      } catch (e) {
        this.$buefy.toast.open({
          message: 'Something went wrong!',
          type: 'is-danger',
        })
        this.isLoading = false
      }
    },
    shouldDisableButton() {
      return this.isLoading
    },
  },
})
