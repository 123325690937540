import Vue from 'vue'
import { buildQueryString } from '@/lib/utils'

export default Vue.extend({
  name: 'QueryParamsMixin',
  data() {
    return {
      search: '',
      currentPage: 1,
      itemsPerPage: 25,
    }
  },
  watch: {
    search() {
      this.updateQueryString()
    },
    currentPage() {
      this.updateQueryString()
    },
  },
  created() {
    this.parseQueryString()
  },
  methods: {
    /**
     * Update the current query string with search input, current page & algolia filter string
     */
    updateQueryString() {
      const queryString = buildQueryString({
        searchText: this.search,
        page: this.currentPage,
        // this.filterString isn't defined here because it's specific
        // to the page that builds it. Therefore, it throws a type error.

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // algoliaFilterString: this.filterString,
      })
      window.history.pushState({}, '', `?${queryString}`)
    },
    async parseQueryString() {
      const queryParams = this.$route.query

      if (!queryParams || !Object.keys(queryParams).length) {
        return
      }

      this.currentPage = Number(queryParams.page)
      this.search = queryParams.query as string

      if (queryParams.filters) {
        const andFilters = (queryParams.filters as string)
          .split('AND')
          .map((value) => value.trim())

        andFilters.forEach((orFilter) => {
          const isSelectedBooleanFilter =
            orFilter.includes('true') || orFilter.includes('false')

          if (isSelectedBooleanFilter) {
            const [filterName, value] = orFilter.split(':')

            if (value === 'true') {
              // this.boolean isn't defined here because it's specific
              // to the page that uses it. Therefore, it throws a type error.

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              this.booleanFilters.push(filterName)
            }
          } else {
            const orFilterParts = orFilter
              .split('OR')
              .map((value) => value.trim())
            const filterName = orFilterParts[0].split(':')[0]
            const orFilterValues = orFilterParts.map(
              (value) => value.split(':')[1]
            )

            // The filter name is derived from the algolia filter string,
            // therefore this can be dynamic. But we must remember to always
            // name the filter arrray the same as the indexed attribute.
            // E.g. Algolia attribute: paymentStatus, array name: paymentStatus

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this[filterName] = this[filterName].concat(orFilterValues)
          }
        })
      }

      await this.$nextTick()
      this.$forceUpdate()
    },
  },
})
