

















































import Vue, { PropType } from 'vue'
import EditAddress from '../customers/EditAddress.vue'
import { Address } from '../../../../shared/types/types'
import { updateCustomerInfo } from '../../lib/user'
import { CustomerInput } from '../../../../shared/types/types'

export default Vue.extend({
  name: 'AddressCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    page: {
      type: String,
      default: '',
    },
    address: {
      type: Object as PropType<Address>,
      required: true,
    },
    additionalAddresses: {
      type: Array as PropType<Address[]>,
      default: Array,
    },
    enableCopy: {
      type: Boolean,
      default: true,
    },
    enableEdit: {
      type: Boolean,
      default: true,
    },
    orderId: {
      type: String,
      default: '',
    },
    customerId: {
      type: String,
      default: '',
    },
    firstName: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      addressDetails: { ...this.address },
      customer: {} as CustomerInput,
      enableViewMap: this.address.longitude && this.address.latitude,
    }
  },
  computed: {
    mapsLink(): string {
      return `https://maps.google.com/?q=${this.addressDetails.latitude},${this.addressDetails.longitude}`
    },
  },
  methods: {
    copyAddress() {
      // TODO: Implement - copy to clipboard
    },
    editAddress() {
      this.$buefy.modal.open({
        parent: this,
        component: EditAddress,
        trapFocus: true,
        events: {
          'customer-address': (value: any) => {
            this.customerEditAddress(value)
          },
          'update-address': (address: Address) => {
            this.addressDetails = { ...address }
          },
        },
        props: {
          additionalAddresses: this.additionalAddresses,
          address: this.addressDetails,
          title: this.title,
          orderId: this.orderId,
          page: this.page,
        },
      })
    },
    async customerEditAddress(data: any) {
      switch (this.title) {
        case 'Shipping Address':
          this.customer = {
            customerId: this.customerId,
            firstName: this.firstName,
            email: this.email,
            shippingAddress: data,
          }
          await this.updateAddress()
          break
        case 'Billing Address':
          this.customer = {
            customerId: this.customerId,
            firstName: this.firstName,
            email: this.email,
            billingAddress: data,
          }
          await this.updateAddress()
          break
      }
    },
    async updateAddress() {
      try {
        const response = await updateCustomerInfo(
          this.customer,
          this.customerId
        )
        if (response) {
          this.$buefy.toast.open({
            message: 'Customer Updated Successfully!',
            type: 'is-success',
            position: 'is-bottom',
          })
          if (this.title === 'Shipping Address') {
            this.addressDetails = response.shippingAddress
          } else {
            this.addressDetails = response.billingAddress
          }
          this.$emit('close')
        }
      } catch (e) {
        this.$buefy.toast.open({
          message: 'Something went wrong!',
          type: 'is-danger',
          position: 'is-bottom',
        })
      }
    },
  },
})
