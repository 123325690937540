

























































import Vue from 'vue'
export default Vue.extend({
  name: 'AddAddress',
  props: {
    addressName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isFormValid: true,
    }
  },
  methods: {
    saveAddress() {
      //TODO: Save data to backend
      this.$emit('close')
    },
  },
})
