<template>
  <div class="flex">
    <div class="flex-1 mr-4 h-screen">
      <div class="card mb-5 h-2/5">
        <div class="card-content">
          <div class="mb-5">
            <b-skeleton width="20%" :animated="true" />
            <b-skeleton width="40%" :animated="true" />
            <b-skeleton width="80%" :animated="true" />
            <b-skeleton :animated="true" />
          </div>
          <div>
            <b-skeleton width="20%" :animated="true" />
            <b-skeleton width="40%" :animated="true" />
            <b-skeleton width="80%" :animated="true" />
            <b-skeleton :animated="true" />
            <b-skeleton :animated="true" />
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-content">
          <div class="mb-5">
            <b-skeleton width="20%" :animated="true" />
            <b-skeleton width="40%" :animated="true" />
            <b-skeleton width="80%" :animated="true" />
            <b-skeleton :animated="true" />
          </div>
          <div>
            <b-skeleton width="20%" :animated="true" />
            <b-skeleton width="40%" :animated="true" />
            <b-skeleton width="80%" :animated="true" />
            <b-skeleton :animated="true" />
          </div>
        </div>
      </div>
    </div>
    <div class="max-w-xs w-1/2 h-screen">
      <div class="card h-1/5 mb-5">
        <div class="card-content h-100">
          <b-skeleton width="20%" :animated="true" />
          <b-skeleton width="40%" :animated="true" />
          <b-skeleton width="80%" :animated="true" />
          <b-skeleton :animated="true" />
        </div>
      </div>
      <div class="card h-2/5">
        <div class="card-content h-100">
          <div class="mb-5">
            <b-skeleton width="20%" :animated="true" />
            <b-skeleton width="40%" :animated="true" />
            <b-skeleton width="80%" :animated="true" />
            <b-skeleton :animated="true" />
          </div>
          <div>
            <b-skeleton width="20%" :animated="true" />
            <b-skeleton width="40%" :animated="true" />
            <b-skeleton width="80%" :animated="true" />
            <b-skeleton :animated="true" />
            <b-skeleton :animated="true" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'Separator',
  data() {
    return {}
  },
})
</script>
