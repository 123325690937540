































































































































import Vue, { PropType } from 'vue'
import ModalHeader from '../shared/ModalHeader.vue'
import {
  setCustomerBillingAddress,
  setCustomerShippingAddress,
} from '../../lib/order'
import {
  Address,
  OrderBillingAddressInput,
  OrderShippingAddressInput,
} from '../../../../shared/types/types'
import { data as countries } from '@/assets/countries.json'

export default Vue.extend({
  name: 'EditAddress',
  components: {
    // In order to avoid circular dependency -> https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components
    AddressCard: () => import('../shared/AddressCard.vue'),
    ModalHeader,
  },
  props: {
    address: {
      type: Object as PropType<Address>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    page: {
      type: String,
      default: '',
    },
    additionalAddresses: {
      type: Array as PropType<Address[]>,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isFormValid: true,
      currentAddress: { ...this.address },
      countries,
      requiredFields: [
        'address1',
        'city',
        'country',
        'firstName',
        'lastName',
        'phone',
      ],
      isLoading: false,
    }
  },
  computed: {
    dropdownIcon() {
      return (active: boolean) => (active ? 'menu-up' : 'menu-down')
    },
    hasAdditionalAddresses(): number {
      return this.additionalAddresses.length
    },
    modalCardHeader(): string {
      return `Edit ${this.title}`
    },
    countryData(): Record<string, any> | undefined {
      return this.countries.find(
        (country) => country.name === this.currentAddress.country
      )
    },
    divisions(): Record<string, any>[] | undefined {
      return this.countryData?.divisions?.data
    },
    divisionLabel(): string | undefined {
      return this.countryData?.divisions?.name
    },
    addressCode(): string | null {
      if (this.countryData?.postalCode) {
        return 'Postal Code'
      }

      if (this.countryData?.zipCode) {
        return 'Zip Code'
      }

      return null
    },
    countryDropdownButtonText(): string {
      return this.currentAddress.country || 'Please select a country'
    },
    divisionDropdownButtonText(): string {
      return this.currentAddress.division || 'Please Choose'
    },
  },
  methods: {
    validateForm() {
      this.isFormValid = true
      for (const [key, val] of Object.entries(this.currentAddress)) {
        if (this.requiredFields.includes(key) && !(val as string).trim()) {
          this.isFormValid = false
          break
        }
      }
    },
    async saveAddress() {
      this.isLoading = true
      if (this.page === 'Customer') {
        delete this.currentAddress['__typename']
        this.$emit('customer-address', this.currentAddress)
      } else {
        try {
          this.isLoading = true
          switch (this.title) {
            case 'Billing Address':
              {
                const order = await this.setCustomerBillingAddress()
                this.isLoading = false
                this.$buefy.toast.open({
                  message: 'Billing Address has been updated!',
                  type: 'is-success',
                  position: 'is-bottom',
                })
                this.$emit('update-address', order?.customer?.billingAddress)
              }
              break
            case 'Shipping Address':
              {
                const order = await this.setCustomerShippingAddress()
                this.isLoading = false
                this.$buefy.toast.open({
                  message: 'Shipping Address has been updated!',
                  type: 'is-success',
                  position: 'is-bottom',
                })
                this.$emit('update-address', order?.customer?.shippingAddress)
                this.$emit('close')
              }
              break
          }
          this.$emit('close')
        } catch (error) {
          this.handleAppSyncError()
        }
      }
      this.$emit('close')
      this.isLoading = false
    },
    handleAppSyncError() {
      this.isLoading = false
      this.$buefy.toast.open({
        message: 'Failed to update the address. Please try again later.',
        type: 'is-danger',
        position: 'is-bottom',
      })
    },
    async setCustomerBillingAddress() {
      const billingAddress = { billingAddress: { ...this.currentAddress } }
      delete billingAddress.billingAddress['__typename']
      return await setCustomerBillingAddress(
        this.orderId,
        billingAddress as OrderBillingAddressInput
      )
    },
    async setCustomerShippingAddress() {
      const shippingAddress = { shippingAddress: { ...this.currentAddress } }
      delete shippingAddress.shippingAddress['__typename']
      return await setCustomerShippingAddress(
        this.orderId,
        shippingAddress as OrderShippingAddressInput
      )
    },
    shouldDisableButton() {
      return !this.isFormValid || this.isLoading
    },
  },
})
