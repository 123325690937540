<template>
  <header class="modal-card-head">
    <p class="modal-card-title">{{ title }}</p>
    <span v-show="showClose" class="cursor-pointer" @click="$emit('close')">
      <b-icon icon="close" />
    </span>
  </header>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'ModalHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
  },
})
</script>
