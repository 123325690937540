













































import Vue, { PropType } from 'vue'
import { updateCustomerInfo, validateEmail } from '../../lib/user'
import { CustomerInput, Customer } from '../../../../shared/types/types'

export default Vue.extend({
  name: 'EditCustomerContactInfo',
  props: {
    customerInfo: {
      type: Object as PropType<Customer>,
      required: true,
    },
  },
  data() {
    return {
      isFormValid: true,
      isLoading: false,
      editCustomer: {} as CustomerInput,
    }
  },
  created() {
    this.editCustomer = {
      firstName: this.customerInfo?.firstName || '',
      lastName: this.customerInfo.lastName,
      email: this.customerInfo?.email || '',
      customerId: this.customerInfo?.customerId || '',
    }
  },
  methods: {
    async saveCustomerInfo() {
      this.isLoading = true
      try {
        const response = await updateCustomerInfo(
          this.editCustomer,
          this.editCustomer.customerId
        )
        if (response) {
          this.$buefy.toast.open({
            message: 'Customer Updated Successfully!',
            type: 'is-success',
          })
          this.$emit('update-customer-info', response)
          this.$emit('close')
          this.isLoading = false
        }
      } catch (e) {
        this.$buefy.toast.open({
          message: 'Something went wrong!',
          type: 'is-danger',
        })
        this.isLoading = false
      }
    },
    async validateForm() {
      this.isLoading = true
      try {
        const response = await validateEmail(this.editCustomer.email)
        if (response) {
          if (response.isValid === false) {
            this.isFormValid = false
            this.isLoading = false
          } else {
            this.isFormValid = true
            this.isLoading = false
          }
        }
      } catch (e) {
        console.log('error : ', e)
      }
      for (const [, val] of Object.entries(this.editCustomer)) {
        if ((val as string).trim() === '') {
          this.isFormValid = false
          break
        }
      }
    },
    shouldDisableButton() {
      return !this.isFormValid || this.isLoading
    },
  },
})
